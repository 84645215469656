/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
 to convert the hex code into rgb for using it inside the rgba color format.
 */

// Material Kit 2 PRO React helper functions
import hexToRgb from "assets/theme/functions/hexToRgb";

function rgba(color, opacity) {
    return `rgba(${hexToRgb(color)}, ${opacity})`;
}

export default rgba;
