/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.aquire360.com/product/material-kit-pro-react
* Copyright 2023 Aquire360 (https://www.aquire360.com)

Coded by www.aquire360.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {motion} from "framer-motion";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import image1 from 'assets/images/network-3396348_1920.jpg';
import MKTypography from "../../../../components/MKTypography";

function Information() {
    return (
        <MKBox component="section" py={12}>
            <Container>
                <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} lg={6}>
                        <Grid container justifyContent="flex-start">
                            <Grid item xs={12}>
                                <MKTypography variant={'h3'}>Undetected issues during M&A can:</MKTypography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MKBox mb={5}>
                                    <motion.div
                                        initial={{opacity: 0, y: 100}}
                                        whileInView={{opacity: 1, y: 0}}
                                        transition={{delay: 0, duration: 1}}
                                    >
                                        <DefaultInfoCard
                                            icon="public"
                                            title="Compromise proprietary intellectual property"
                                            description="Compromising proprietary intellectual property poses a significant risk to a company's competitive advantage and security."
                                        />
                                    </motion.div>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MKBox mb={5}>
                                    <motion.div
                                        initial={{opacity: 0, y: 100}}
                                        whileInView={{opacity: 1, y: 0}}
                                        transition={{delay: 0.25, duration: 1}}
                                    >
                                        <DefaultInfoCard
                                            icon="dataset"
                                            title="Put sensitive data at risk"
                                            description="Putting sensitive data at risk jeopardizes privacy and security."
                                        />
                                    </motion.div>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MKBox mb={{xs: 5, md: 0}}>
                                    <motion.div
                                        initial={{opacity: 0, y: 100}}
                                        whileInView={{opacity: 1, y: 0}}
                                        transition={{delay: 0.5, duration: 1}}
                                    >
                                        <DefaultInfoCard
                                            icon="apps"
                                            title="Impede overall and integration operations"
                                            description="Undetected issues during an acquisition can significantly hinder overall integration and operational efficiency."
                                        />
                                    </motion.div>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MKBox mb={{xs: 5, md: 0}}>
                                    <motion.div
                                        initial={{opacity: 0, y: 100}}
                                        whileInView={{opacity: 1, y: 0}}
                                        transition={{delay: 0.75, duration: 1}}
                                    >
                                        <DefaultInfoCard
                                            icon="3p"
                                            title="Lengthen deal and integration timelines"
                                            description="Significantly extend both the deal negotiation process and integration timelines, potentially impacting the overall success of the merger."
                                        />
                                    </motion.div>
                                </MKBox>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <MKBox mb={{xs: 5, md: 0}}>
                                    <motion.div
                                        initial={{opacity: 0, y: 100}}
                                        whileInView={{opacity: 1, y: 0}}
                                        transition={{delay: 0.5, duration: 1}}
                                    >
                                        <DefaultInfoCard
                                            icon="money"
                                            title="Increase remediation costs"
                                            description="Elevate remediation costs, emphasizing the critical importance of thorough due diligence to avoid unnecessary financial burdens."
                                        />
                                    </motion.div>
                                </MKBox>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={4} sx={{ml: "auto", mt: {xs: 3, lg: 0}}}>
                        <motion.div
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            transition={{delay: 0.25, duration: 1}}
                        >
                            <CenteredBlogCard
                                image={image1}
                                title="Our Value Proposition"
                                description="In any M&A, when you move beyond the decision to buy and software is part of the deal, knowing what’s in the code matters. Understanding potential open source risks, security flaws, and code quality issues in a target’s codebase early protect the value of the deal."

                            />
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Information;
