/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import * as ReactDOMClient from "react-dom/client";
import {BrowserRouter} from "react-router-dom";
import App from "App";

const container = document.getElementById("root");
const root = ReactDOMClient.createRoot(container);

root.render(
    <BrowserRouter>
        <App/>
    </BrowserRouter>
);
