/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.aquire360.com/product/material-kit-pro-react
* Copyright 2023 Aquire360 (https://www.aquire360.com)

Coded by www.aquire360.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/Rajiv.png";
import team2 from "assets/images/Trevor.png";
import team3 from "assets/images/ivana-squares.jpg";
import team4 from "assets/images/ivana-square.jpg";

function Team() {
    return (
        <MKBox
            component="section"
            variant="gradient"
            bgColor="dark"
            position="relative"
            py={6}
            px={{xs: 2, lg: 0}}
            mx={-2}
        >
            <Container>
                <Grid container>
                    <Grid item xs={12} md={8} sx={{mb: 6}}>
                        <MKTypography variant="h3" color="white">
                            The Executive Team
                        </MKTypography>
                        <MKTypography variant="body2" color="white" opacity={0.8}>
                            Our executive team's exceptional leadership and collective expertise drive our company's
                            innovation and growth, setting an inspiring standard for excellence in the industry.
                        </MKTypography>
                    </Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={1}>
                            <HorizontalTeamCard
                                image={team1}
                                name="Rajiv Amar"
                                position={{color: "info", label: "Co-Founder"}}
                                description="As a data-driven problem solver, Rajiv creates collaborative, engaging work environments and partnerships which promote the creation of ground-breaking solutions."
                            />
                        </MKBox>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                        <MKBox mb={1}>
                            <HorizontalTeamCard
                                image={team2}
                                name="Trevor Moore"
                                position={{color: "info", label: "Co-Founder"}}
                                description="A highly experienced technology leader with proven expertise to drive strategic initiatives and facilitate business growth through innovative, cost-effective solutions."
                            />
                        </MKBox>
                    </Grid>
                    {false & <Grid item xs={12} lg={6}>
                        <MKBox mb={{xs: 1, lg: 0}}>
                            <HorizontalTeamCard
                                image={team3}
                                name="Ivana Flow"
                                position={{color: "info", label: "Athlete"}}
                                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
                            />
                        </MKBox>
                    </Grid>}
                    {false && <Grid item xs={12} lg={6}>
                        <MKBox mb={{xs: 1, lg: 0}}>
                            <HorizontalTeamCard
                                image={team4}
                                name="Marquez Garcia"
                                position={{color: "info", label: "JS Developer"}}
                                description="Artist is a term applied to a person who engages in an activity deemed to be an art."
                            />
                        </MKBox>
                    </Grid>}
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Team;
