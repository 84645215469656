/**
 =========================================================
 * Material Kit 2 PRO React React - v1.0.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/soft-ui-dashboard-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {forwardRef} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MKButton
import MKButtonRoot from "components/MKButton/MKButtonRoot";

const MKButton = forwardRef(
    ({color, variant, size, circular, iconOnly, children, ...rest}, ref) => (
        <MKButtonRoot
            {...rest}
            ref={ref}
            color="primary"
            variant={variant === "gradient" ? "contained" : variant}
            size={size}
            ownerState={{color, variant, size, circular, iconOnly}}
        >
            {children}
        </MKButtonRoot>
    )
);

// Setting default values for the props of MKButton
MKButton.defaultProps = {
    size: "medium",
    variant: "contained",
    color: "white",
    circular: false,
    iconOnly: false,
};

// Typechecking props for the MKButton
MKButton.propTypes = {
    size: PropTypes.oneOf(["small", "medium", "large"]),
    variant: PropTypes.oneOf(["text", "contained", "outlined", "gradient"]),
    color: PropTypes.oneOf([
        "default",
        "white",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    circular: PropTypes.bool,
    iconOnly: PropTypes.bool,
    children: PropTypes.node.isRequired,
};

export default MKButton;
