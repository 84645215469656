/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */
import {motion} from 'framer-motion';

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function HorizontalTeamCard({image, name, position, imageProps, description}) {
    return (
        <Card sx={{mt: 3}}>
            <Grid container>
                <Grid item xs={12} md={6} lg={4} sx={{mt: -6}}>
                    <motion.div
                        initial={{opacity: 0, y: -100}}
                        whileInView={{opacity: 1, y: 0}}
                        transition={{delay: 0.5, duration: 1}}
                    >
                        <MKBox width="100%" pt={2} pb={1} px={2}>
                            <MKBox
                                component="img"
                                src={image}
                                alt={name}
                                width="100%"
                                borderRadius="md"
                                shadow="lg"
                                {...imageProps}
                            />
                        </MKBox>
                    </motion.div>
                </Grid>
                <Grid item xs={12} md={6} lg={8} sx={{my: "auto"}}>
                    <MKBox pt={{xs: 1, lg: 2.5}} pb={2.5} pr={4} pl={{xs: 4, lg: 1}} lineHeight={1}>
                        <motion.div
                            initial={{opacity: 0, x: 100}}
                            whileInView={{opacity: 1, x: 0}}
                            transition={{delay: 0.5, duration: 1}}
                        >
                            <MKTypography variant="h5">{name}</MKTypography>
                        </motion.div>
                        <motion.div
                            initial={{opacity: 0, x: 100}}
                            whileInView={{opacity: 1, x: 0}}
                            transition={{delay: 0.5, duration: 1}}
                        >
                            <MKTypography variant="h6" color={position.color} mb={1}>
                                {position.label}
                            </MKTypography>
                        </motion.div>
                        <motion.div
                            initial={{opacity: 0, y: 100}}
                            whileInView={{opacity: 1, y: 0}}
                            transition={{delay: 0.5, duration: 1}}
                        >
                            <MKTypography variant="body2" color="text">
                                {description}
                            </MKTypography>
                        </motion.div>
                    </MKBox>
                </Grid>
            </Grid>
        </Card>
    );
}

// Typechecking props for the HorizontalTeamCard
HorizontalTeamCard.propTypes = {
    image: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    position: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
        ]),
        label: PropTypes.string.isRequired,
    }).isRequired,
    description: PropTypes.string.isRequired,
};

export default HorizontalTeamCard;
