/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";

// Material Kit 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";
import boxShadow from "assets/theme/functions/boxShadow";

const {white} = colors;

export default {
    styleOverrides: {
        root: {
            background: "#9fc9ff",
            fill: "#9fc9ff",
            stroke: "#9fc9ff",
            strokeWidth: pxToRem(10),
            width: pxToRem(13),
            height: pxToRem(13),
            borderRadius: "50%",
            zIndex: 99,
            transition: "all 200ms linear",

            "&.Mui-active": {
                background: white.main,
                fill: white.main,
                stroke: white.main,
                borderColor: white.main,
                boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
            },

            "&.Mui-completed": {
                background: white.main,
                fill: white.main,
                stroke: white.main,
                borderColor: white.main,
                boxShadow: boxShadow([0, 0], [0, 2], white.main, 1),
            },
        },
    },
};
