/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";

// Material Kit 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {transparent} = colors;

export default {
    styleOverrides: {
        select: {
            display: "grid",
            alignItems: "center",
            padding: `0 ${pxToRem(12)} !important`,

            "& .Mui-selected": {
                backgroundColor: transparent.main,
            },
        },

        selectMenu: {
            background: "none",
            height: "none",
            minHeight: "none",
            overflow: "unset",
        },

        icon: {
            display: "none",
        },
    },
};
