/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";
import boxShadows from "assets/theme/base/boxShadows";
import borders from "assets/theme/base/borders";

const {transparent} = colors;
const {lg} = boxShadows;
const {borderRadius} = borders;

export default {
    styleOverrides: {
        paper: {
            backgroundColor: transparent.main,
            boxShadow: lg,
            borderRadius: borderRadius.md,
        },
    },
};
