/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.aquire360.com/product/material-kit-pro-react
* Copyright 2023 Aquire360 (https://www.aquire360.com)

Coded by www.aquire360.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {useState} from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleInfoCard from "examples/Cards/InfoCards/SimpleInfoCard";

// HelpCenter page components
import FaqCollapse from "pages/Support/Faq/components/FaqCollapse";

// Routes
import routes from "routes";
import footerRoutes from "../../../footer.routes";
import DefaultFooter from "../../../examples/Footers/DefaultFooter";

function Faq() {
    const [collapse, setCollapse] = useState(false);

    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky
            />
            <MKBox component="section" pt={20} pb={12}>
                <Container sx={{mb: 8}}>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6} lg={4}>
                            <SimpleInfoCard
                                icon="description"
                                title="Detailed Documentation"
                                description="We ensure meticulous documentation to offer our clients comprehensive insights and a solid foundation for informed decision-making."
                                direction="center"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <SimpleInfoCard
                                icon="support"
                                title="Amazing Support"
                                description="We excel in providing exceptional support that goes above and beyond, ensuring our clients receive nothing less than an outstanding experience."
                                direction="center"
                            />
                        </Grid>
                        <Grid item xs={12} md={6} lg={4}>
                            <SimpleInfoCard
                                icon="grid_view"
                                title="A True Acquisition"
                                description="We empower our clients with the strategic insights and expertise necessary to orchestrate and achieve a successful acquisition."
                                direction="center"
                            />
                        </Grid>
                    </Grid>
                </Container>
                <Container>
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <Card>
                                <MKBox
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    p={3}
                                    mt={-3}
                                    mx={2}
                                >
                                    <MKTypography variant="h3" color="white">
                                        FAQ
                                    </MKTypography>
                                    <MKTypography variant="body2" color="white" opacity={0.8}>
                                        Last modified: Sep 05 2023
                                    </MKTypography>
                                </MKBox>
                                <MKBox p={6}>
                                    <MKTypography variant="h5" my={3}>
                                        Basics
                                    </MKTypography>
                                    <FaqCollapse
                                        title="Why do I need a code review while buying a software company? What are the risks?"
                                        open={collapse === 1}
                                        onClick={() => (collapse === 1 ? setCollapse(false) : setCollapse(1))}
                                    >
                                        M&A code reviews require learning code from - When initiating a code review as
                                        part of a merger or acquisition, you’ve likely never seen the software assets in
                                        question before. For this process to work, the code reviewer must be able to
                                        start from nothing and quickly learn the software’s code to provide feedback.
                                    </FaqCollapse>
                                    <FaqCollapse
                                        title="Why our expertise matters in reviewing Software Code?"
                                        open={collapse === 2}
                                        onClick={() => (collapse === 2 ? setCollapse(false) : setCollapse(2))}
                                    >
                                        M&A code reviews happen on short timelines.
                                        <br/>
                                        <br/>
                                        Standard code reviews can often unfold over several weeks. Merger and
                                        acquisition code reviews, however, typically must be completed in no more than a
                                        few weeks because the due diligence is limited. The code reviewer must not only
                                        be able to work within this time crunch, but also be able to respond promptly to
                                        last-minute requests.
                                    </FaqCollapse>
                                    <FaqCollapse
                                        title="What’s under the hood?"
                                        open={collapse === 3}
                                        onClick={() => (collapse === 3 ? setCollapse(false) : setCollapse(3))}
                                    >
                                        Prior to requesting a code review, you may have used the software application or
                                        viewed a demo of it, but ultimately, this doesn’t offer any insight into the
                                        actual quality of the source code. A flashy, well-designed front-end could still
                                        be supported by inadequate underlying code, or vice-versa. The only way to
                                        determine this is a thorough code review during the M&A due diligence process.
                                    </FaqCollapse>

                                    <FaqCollapse
                                        title="Why M&A code reviews involve atypical inspection needs."
                                        open={collapse === 6}
                                        onClick={() => (collapse === 6 ? setCollapse(false) : setCollapse(6))}
                                    >
                                        An ordinary code review wouldn’t typically include the study of how many
                                        developers worked on the application and for how long. However, in a merger and
                                        acquisition code review, you may be looking to understand the software team’s
                                        level of productivity and find out who is outperforming others or who isn’t
                                        pulling their weight.
                                    </FaqCollapse>
                                    <FaqCollapse
                                        title="Can't I rely on code report produced by my internal team or software company 's team"
                                        open={collapse === 7}
                                        onClick={() => (collapse === 7 ? setCollapse(false) : setCollapse(7))}
                                    >
                                        M&A code review clients often need a hands-off review process.
                                        <br/>
                                        <br/>
                                        As part of the merger or acquisition, you no doubt have your hands full with
                                        other due diligence matters and don’t have the time, interest, or technology
                                        background to be a broker between the code reviewer and the company with the
                                        software assets in question. In an M&A code review, the code reviewer can take
                                        this problem off your hands by interacting directly with the company that owns
                                        the source code without involving you.
                                    </FaqCollapse>
                                    <FaqCollapse
                                        title="What happens if software code is not fully available or without proper documentation?"
                                        open={collapse === 8}
                                        onClick={() => (collapse === 8 ? setCollapse(false) : setCollapse(8))}
                                    >
                                        M&A code reviews can be part of a hostile situation.
                                        <br/>
                                        <br/>
                                        Sometimes mergers and acquisitions are hostile in nature. The company that owns
                                        the software assets may refuse to release documentation or the full source code
                                        due to restrictive security policies, and in extreme scenarios, the source code
                                        may be entirely unavailable. The code reviewer may be required to perform a
                                        zero-knowledge review that requires reverse engineering software with only
                                        publicly available information to assist </FaqCollapse>
                                    <FaqCollapse
                                        title="Why M&A code reviews are different?"
                                        open={collapse === 9}
                                        onClick={() => (collapse === 9 ? setCollapse(false) : setCollapse(9))}
                                    >
                                        M&A code reviews require more customization.
                                        <br/>
                                        <br/>
                                        Merger and acquisition code reviews are targeted to specific areas of interest
                                        or concern and are therefore typically geared more toward estimating software
                                        value rather than generating a list of bugs in the code that must be corrected.
                                        Your code review report will need to be customized to your specific needs and
                                        questions.
                                    </FaqCollapse>

                                </MKBox>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox pt={{xs: 0, lg: 3}} pb={3}>
                <DefaultFooter content={footerRoutes}/>
            </MKBox>
        </>
    );
}

export default Faq;
