/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.aquire360.com/product/material-kit-pro-react
* Copyright 2023 Aquire360 (https://www.aquire360.com)

Coded by www.aquire360.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Data
import data from "pages/presentation/sections/data/designBlocksData";

import {motion, MotionConfig} from "framer-motion";

// react-router-dom components
import {Link} from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/presentation/components/ExampleCard";

function DesignBlocks() {
    const renderData = data.map(({title, description, items}) => (
        <Grid container spacing={3} sx={{mb: 10}} key={title}>
            <Grid item xs={12} lg={3}>
                <motion.div
                    initial={{opacity: 0, y: 400}}
                    whileInView={{opacity: 1, y: 0}}
                >
                    <MKBox position="sticky" top="100px" pb={{xs: 2, lg: 6}}>
                        <MKTypography variant="h3" fontWeight="bold" mb={1}>
                            {title}
                        </MKTypography>
                        <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
                            {description}
                        </MKTypography>
                    </MKBox>
                </motion.div>
            </Grid>
            <Grid item xs={12} lg={9}>
                <Grid container spacing={3}>
                    {items.map(({image, name, count, route}, index) => (
                        <Grid item xs={12} md={4} sx={{mb: 2}} key={name}>
                            <Link to={route}>
                                <motion.div
                                    initial={{opacity: 0, y: 400}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{delay: index % 3 * 0.25, duration: 1}}
                                >
                                    <ExampleCard backgroundImage={image} name={name} count={count}/>
                                </motion.div>
                            </Link>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    ));

    return (
        <MKBox component="section" my={6} py={6}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={6}
                    flexDirection="column"
                    alignItems="center"
                    sx={{textAlign: "center", my: 6, mx: "auto", px: 0.75}}
                >
                    <MKBadge
                        variant="contained"
                        color="info"
                        badgeContent="Infinite opportunities"
                        container
                        sx={{mb: 2}}
                    />
                    <MKTypography variant="h2" fontWeight="bold" sx={{mb: 2}}>
                        About Aquire360
                    </MKTypography>
                    <MKTypography variant="h4" sx={{mb: 2}}>
                        Trustworthy | Accurate | Affordable
                    </MKTypography>
                    <MKTypography variant="h4" sx={{mb: 2}}>
                        Professional Appraisals By a Certified Professional
                    </MKTypography>
                    <MKTypography variant="body1" color="text">
                        The team is familiar with the latest tools and technologies like Osler, Codacy, Blackduck, Cast,
                        Dynatrace, smart-ts, sonarsource, synopsys, veracity, squoring
                        Aquire360 is a management consulting single stop shop assisting you with a full
                        appraisal of underlying software assets,
                        Please note that we are not a stamp the deal "report creating" a firm, we provide an unbiased
                        full spectrum, full process appraisal for your data driven decsions
                    </MKTypography>
                </Grid>
            </Container>
            <MotionConfig transition={{duration: 1}}>
                <Container sx={{mt: 6}}>{renderData}</Container>
            </MotionConfig>
        </MKBox>
    );
}

export default DesignBlocks;
