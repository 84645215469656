/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// @mui material components
import Fade from "@mui/material/Fade";

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";

// Material Kit 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {black, light} = colors;
const {size, fontWeightRegular} = typography;
const {borderRadius} = borders;

export default {
    defaultProps: {
        arrow: true,
        TransitionComponent: Fade,
    },

    styleOverrides: {
        tooltip: {
            maxWidth: pxToRem(200),
            backgroundColor: black.main,
            color: light.main,
            fontSize: size.sm,
            fontWeight: fontWeightRegular,
            textAlign: "center",
            borderRadius: borderRadius.md,
            opacity: 0.7,
            padding: `${pxToRem(5)} ${pxToRem(8)} ${pxToRem(4)}`,
        },

        arrow: {
            color: black.main,
        },
    },
};
