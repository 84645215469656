/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {forwardRef} from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for MKAvatar
import MKAvatarRoot from "components/MKAvatar/MKAvatarRoot";

const MKAvatar = forwardRef(({bgColor, size, shadow, ...rest}, ref) => (
    <MKAvatarRoot ref={ref} ownerState={{shadow, bgColor, size}} {...rest} />
));

// Setting default values for the props of MKAvatar
MKAvatar.defaultProps = {
    bgColor: "transparent",
    size: "md",
    shadow: "none",
};

// Typechecking props for the MKAvatar
MKAvatar.propTypes = {
    bgColor: PropTypes.oneOf([
        "transparent",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    size: PropTypes.oneOf(["xs", "sm", "md", "lg", "xl", "xxl"]),
    shadow: PropTypes.oneOf(["none", "xs", "sm", "md", "lg", "xl", "xxl", "inset"]),
};

export default MKAvatar;
