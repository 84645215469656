/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import boxShadows from "assets/theme/base/boxShadows";

// Material Kit 2 PRO React helper functions
// import rgba from "assets/theme/functions/rgba";
import pxToRem from "assets/theme/functions/pxToRem";
import linearGradient from "assets/theme/functions/linearGradient";

const {white, gradients, grey, transparent} = colors;
const {borderWidth} = borders;
const {md} = boxShadows;

export default {
    defaultProps: {
        disableRipple: false,
    },

    styleOverrides: {
        switchBase: {
            color: gradients.dark.main,

            "&:hover": {
                backgroundColor: transparent.main,
            },

            "&.Mui-checked": {
                color: gradients.dark.main,

                "&:hover": {
                    backgroundColor: transparent.main,
                },

                "& .MuiSwitch-thumb": {
                    borderColor: `${gradients.dark.main} !important`,
                },

                "& + .MuiSwitch-track": {
                    backgroundColor: `${gradients.dark.main} !important`,
                    borderColor: `${gradients.dark.main} !important`,
                    opacity: 1,
                },
            },

            "&.Mui-disabled + .MuiSwitch-track": {
                opacity: "0.3 !important",
            },

            "&.Mui-focusVisible .MuiSwitch-thumb": {
                backgroundImage: linearGradient(gradients.info.main, gradients.info.state),
            },
        },

        thumb: {
            backgroundColor: white.main,
            boxShadow: md,
            border: `${borderWidth[1]} solid ${grey[400]}`,
        },

        track: {
            width: pxToRem(32),
            height: pxToRem(15),
            backgroundColor: grey[400],
            border: `${borderWidth[1]} solid ${grey[400]}`,
            opacity: 1,
        },

        checked: {},
    },
};
