/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.aquire360.com/product/material-kit-pro-react
* Copyright 2023 Aquire360 (https://www.aquire360.com)

Coded by www.aquire360.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import {motion} from "framer-motion";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/rotating-card-bg-front.jpeg";
import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import MKTypography from "../../../components/MKTypography";

function Information() {
    return (
        <MKBox component="section" py={6} my={6}>
            <Container>
                <Grid container item xs={11} spacing={3} alignItems="center" sx={{mx: "auto"}}>
                    <Grid item xs={12} lg={4} sx={{mx: "auto"}}>
                        <RotatingCard>
                            <RotatingCardFront
                                image={bgFront}
                                icon="touch_app"
                                title={
                                    <>
                                        Business Value
                                    </>
                                }
                                description="Every Business is a Software Business"
                            />
                            <RotatingCardBack
                                image={bgBack}
                                title="Appraising the value of the software asset(s) in any funding event"
                                description="Debt financing | Large custom | Dev contract | Equity investment by VC | Corp dev folks evaluating asset | M&A due diligence"
                            />
                        </RotatingCard>
                        <MKTypography variant={'body1'} sx={{mt: 2}}>
                            In any M&A, when you move beyond the decision to buy and software is part of the deal,
                            knowing what’s in the code matters. Understanding potential open source risks, security
                            flaws, and code quality issues in a target’s codebase early protects the value of the
                            deal.
                        </MKTypography>
                    </Grid>
                    <Grid item xs={12} lg={7} sx={{ml: "auto"}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={12}>
                                Undetected issues during M&A can:
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <motion.div
                                    initial={{opacity: 0, y: 400}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{delay: 0.25, duration: 1}}
                                >
                                    <DefaultInfoCard
                                        icon="content_copy"
                                        title="Compromise proprietary intellectual property"
                                        description="Built by developers for developers. Check the foundation and you will find
                    everything inside our documentation."
                                    />
                                </motion.div>
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <motion.div
                                    initial={{opacity: 0, y: 400}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{delay: 0.5, duration: 1}}
                                >
                                    <DefaultInfoCard
                                        icon="flip_to_front"
                                        title="Put sensitive data at risk"
                                        description="The world's most popular react components library for building user interfaces."
                                    />
                                </motion.div>
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{mt: {xs: 0, md: 6}}}>
                            <Grid item xs={12} md={6}>

                                <motion.div
                                    initial={{opacity: 0, y: 400}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{delay: 0.25, duration: 1}}
                                >
                                    <DefaultInfoCard
                                        icon="price_change"
                                        title="Impede overall and integration operations"
                                        description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                                    />
                                </motion.div>
                            </Grid>
                            <Grid item xs={12} md={6}>

                                <motion.div
                                    initial={{opacity: 0, y: 400}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{delay: 0.5, duration: 1}}
                                >
                                    <DefaultInfoCard
                                        icon="devices"
                                        title="Lengthen deal and integration timelines"
                                        description="Regardless of the screen size, the website content will naturally fit the given resolution."
                                    />
                                </motion.div>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <motion.div
                                    initial={{opacity: 0, y: 400}}
                                    whileInView={{opacity: 1, y: 0}}
                                    transition={{delay: 0.25, duration: 1}}
                                >
                                    <DefaultInfoCard
                                        icon="devices"
                                        title="Increase remediation costs"
                                        description="Regardless of the screen size, the website content will naturally fit the given resolution."
                                    />
                                </motion.div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default Information;
