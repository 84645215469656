/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

const {white} = colors;
const {borderWidth} = borders;

export default {
    styleOverrides: {
        root: {
            color: "#9fc9ff",
            transition: "all 200ms linear",

            "&.Mui-active": {
                color: white.main,
            },

            "&.Mui-completed": {
                color: white.main,
            },
        },

        alternativeLabel: {
            top: "14%",
            left: "-50%",
            right: "50%",
        },

        line: {
            borderWidth: `${borderWidth[2]} !important`,
            borderColor: "currentColor",
            opacity: 0.5,
        },
    },
};
