/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Kit 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {size} = typography;
const {text} = colors;
const {borderWidth, borderColor} = borders;

export default {
    styleOverrides: {
        root: {
            padding: pxToRem(16),
            fontSize: size.md,
            color: text.main,
        },

        dividers: {
            borderTop: `${borderWidth[1]} solid ${borderColor}`,
            borderBottom: `${borderWidth[1]} solid ${borderColor}`,
        },
    },
};
