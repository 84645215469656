/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";
import borders from "assets/theme/base/borders";
import typography from "assets/theme/base/typography";

// Material Kit 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {light, text, dark} = colors;
const {borderRadius} = borders;
const {size} = typography;

export default {
    styleOverrides: {
        root: {
            minWidth: pxToRem(160),
            minHeight: "unset",
            padding: `${pxToRem(4.8)} ${pxToRem(16)}`,
            borderRadius: borderRadius.md,
            fontSize: size.sm,
            color: text.main,
            transition: "background-color 300ms ease, color 300ms ease",

            "&:hover, &:focus, &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus": {
                backgroundColor: light.main,
                color: dark.main,
            },
        },
    },
};
