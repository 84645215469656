/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

const {grey} = colors;
const {size} = typography;

export default {
    styleOverrides: {
        li: {
            lineHeight: 0,
        },

        separator: {
            fontSize: size.sm,
            color: grey[600],
        },
    },
};
