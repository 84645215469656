/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

// Material Kit 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

const {borderRadius} = borders;
const {light} = colors;

export default {
    styleOverrides: {
        root: {
            height: pxToRem(6),
            borderRadius: borderRadius.md,
            overflow: "visible",
            position: "relative",
        },

        colorPrimary: {
            backgroundColor: light.main,
        },

        colorSecondary: {
            backgroundColor: light.main,
        },

        bar: {
            height: pxToRem(6),
            borderRadius: borderRadius.sm,
            position: "absolute",
            transform: `translate(0, 0) !important`,
            transition: "width 0.6s ease !important",
        },
    },
};
