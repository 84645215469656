/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.aquire360.com/product/material-kit-pro-react
* Copyright 2023 Aquire360 (https://www.aquire360.com)

Coded by www.aquire360.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import twentyFiveYears from 'assets/images/expertise/25years.jpg'
import softwareEngineering from 'assets/images/expertise/software-engineering.jpg'
import finance from 'assets/images/expertise/finance.jpg'
import process from 'assets/images/expertise/process.jpg'
import market from 'assets/images/expertise/market.jpg'
import businessDevelopment from 'assets/images/expertise/business-development.jpg'
import integrations from 'assets/images/expertise/integrations.jpg'
import data from 'assets/images/expertise/data.jpg'
import mitigation from 'assets/images/process/mitigation.jpg'
import transformation from 'assets/images/process/transformation.jpg'
import codeReview from 'assets/images/services/code-review.jpg'
import dataReview from 'assets/images/services/data-review.jpg'
import languages from 'assets/images/services/languages.jpg'
import mobileReview from 'assets/images/services/mobile-review.jpg'
import infrastructure from 'assets/images/services/infrastructure.jpg'
import configuration from 'assets/images/services/configuration.jpg'
import deployment from 'assets/images/services/deployment.jpg'
import product from 'assets/images/services/product.jpg'


const array = [
    {
        title: "Expertise",
        description: "Our experts go beyond the technology, we provide full services from business development, market, product engineering, infrastructure to ensure success of your transaction. Our extensive report will show value to transaction and potential for short-term & long-term call to action for your business and technology.",
        items: [
            {
                image: twentyFiveYears,
                name: "Over 25 years tech experience in the industry",
                count: 10,
                route: "/pages/company/about-us",
            },
            {
                image: softwareEngineering,
                name: "Full Stack engineering background",
                count: 14,
                route: "/pages/company/about-us",
            },
            {
                image: finance,
                name: "Financial background",
                count: 8,
                route: "/pages/company/about-us",
            },
            {
                image: process,
                name: "Process Engineering",
                count: 1,
                route: "/pages/company/about-us",
            },
            {
                image: market,
                name: "Market assessments experts",
                count: 11,
                route: "/pages/company/about-us",
            },
            {
                image: businessDevelopment,
                name: "Business development gurus",
                count: 11,
                route: "/pages/company/about-us",
            },
            {
                image: integrations,
                name: "Integration experts",
                count: 6,
                route: "/pages/company/about-us",
            },
            {
                image: data,
                name: "Data experts",
                count: 3,
                route: "/pages/company/about-us",
            }
        ],
    },
    {
        title: "Our Process",
        description: "Aquire360 has a 3 step process approach to appraise the company that you are funding layered on our technical inspection and evaluation.",
        items: [
            {
                image: mitigation,
                name: "Mitigation",
                count: 4,
                route: "/pages/company/about-us",
            },
            {
                image: integrations,
                name: "Integration",
                count: 2,
                route: "/pages/company/about-us",
            },
            {
                image: transformation,
                name: "Transformation",
                count: 3,
                route: "/pages/company/about-us",
            },
        ],
    },
    {
        title: "Our Services",
        description: "Trustworthy | Accurate | Affordable",
        items: [
            {
                image: codeReview,
                name: "Application Code Review",
                count: 6,
                route: "/pages/company/about-us",
            },
            {
                image: dataReview,
                name: "Database Review",
                count: 8,
                route: "/pages/company/about-us",
            },
            {
                image: languages,
                name: "Localizaiton",
                count: 3,
                route: "/pages/company/about-us",
            },
            {
                image: mobileReview,
                name: "Mobile Review",
                count: 6,
                route: "/pages/company/about-us",
            },
            {
                image: infrastructure,
                name: "Infrastructure Appraisal",
                count: 6,
                route: "/spages/company/about-us",
            },
            {
                image: configuration,
                name: "Setup & Configuration",
                count: 6,
                route: "/pages/company/about-us",
            },
            {
                image: deployment,
                name: "Deployment",
                count: 6,
                route: "/pages/company/about-us",
            },
            {
                image: market,
                name: "Market Appraisal",
                count: 6,
                route: "/pages/company/about-us",
            },
            {
                image: product,
                name: "Product Appraisal",
                count: 6,
                route: "/pages/company/about-us",
            },
        ],
    }
];
export default array
