/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.aquire360.com/product/material-kit-pro-react
* Copyright 2023 Aquire360 (https://www.aquire360.com)

Coded by www.aquire360.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import {motion} from "framer-motion";
// react-router-dom components
import {Link} from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/presentation/components/ExampleCard";

// Images
import bgPattern from "assets/images/shapes/pattern-lines.svg";

import img1 from "assets/images/process_pyramid.d46f1657.png";

function AuthPages() {
    // pages images

    return (
        <MKBox position="relative" variant="gradient" bgColor="dark" mt={{xs: 0, lg: 12}} mx={-2}>
            <MKBox
                component="img"
                src={bgPattern}
                alt="background-pattern"
                position="absolute"
                top={0}
                left={0}
                width={{xs: "100%", lg: "100%"}}
                height={{xs: "100%", lg: "auto"}}
                opacity={0.6}
            />
            <Container>
                <Grid container alignItems="center">
                    <Grid
                        item
                        xs={12}
                        lg={4}
                        sx={{my: "auto", py: 6, pr: {xs: 3, lg: 2}, pl: {xs: 3, lg: 0}}}
                    >
                        <motion.div
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            transition={{delay: 0.5, duration: 1}}
                        >
                            <MKTypography variant="h2" color="white" mb={3}>
                                Our process towards success.
                            </MKTypography>
                        </motion.div>
                        <motion.div
                            initial={{opacity: 0}}
                            whileInView={{opacity: 1}}
                            transition={{delay: 0.5, duration: 1}}
                        >
                            <MKTypography variant="body2" color="white" mb={2} opacity={0.8}>
                                We stand by your side throughout the entire journey, guiding you from the initial
                                inspection
                                all the way to a successful transformation, ensuring the success of your acquisition at
                                every step.
                            </MKTypography>
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} lg={8} sx={{pl: {xs: 3, lg: 6}, pr: {xs: 3, lg: 6}, pb: 4}}>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sx={{mt: {xs: 0, lg: 6}}}>
                                <Link to="/authentication/sign-up/cover">
                                    <motion.div
                                        initial={{opacity: 0, width: 0, height: 0}}
                                        whileInView={{opacity: 1, width: '100%', height: '100%'}}
                                        transition={{delay: 0.5, duration: 1}}
                                    >
                                        <ExampleCard image={img1} display="grid" minHeight="max-content" p={0.5}/>
                                    </motion.div>
                                </Link>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default AuthPages;
