/**
 =========================================================
 * Material Kit 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.aquire360.com/product/material-kit-pro-react
 * Copyright 2023 Aquire360 (https://www.aquire360.com)

 Coded by www.aquire360.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// Material Kit 2 PRO React helper functions
import pxToRem from "assets/theme/functions/pxToRem";

export default {
    defaultProps: {
        baseClassName: "material-icons-round",
        fontSize: "inherit",
    },

    styleOverrides: {
        fontSizeInherit: {
            fontSize: "inherit !important",
        },

        fontSizeSmall: {
            fontSize: `${pxToRem(20)} !important`,
        },

        fontSizeLarge: {
            fontSize: `${pxToRem(36)} !important`,
        },
    },
};
