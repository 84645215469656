/*
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.aquire360.com/product/material-kit-pro-react
* Copyright 2023 Aquire360 (https://www.aquire360.com)

Coded by www.aquire360.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function Privacy() {
    return (
        <>
            <DefaultNavbar
                routes={routes}
                sticky
            />
            <MKBox component="section" pt={20} pb={12}>
                <Container>
                    <Grid container justifyContent="center">
                        <Grid item xs={12}>
                            <Card>
                                <MKBox
                                    variant="gradient"
                                    bgColor="dark"
                                    borderRadius="lg"
                                    coloredShadow="dark"
                                    p={3}
                                    mt={-3}
                                    mx={2}
                                >
                                    <MKTypography variant="h3" color="white">
                                        Privacy Policy
                                    </MKTypography>
                                    <MKTypography variant="body2" color="white" opacity={0.8}>
                                        Last modified: Sept 07 2023
                                    </MKTypography>
                                </MKBox>
                                <MKBox pb={6} px={6}>
                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        Introduction
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text">
                                        Aquire360 ("we," "our," or "us") is committed to protecting your privacy. This
                                        Privacy Policy describes how we collect, use, and disclose personal information
                                        when
                                        you visit our website.
                                    </MKTypography>
                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        Information We Collect
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text">
                                        We may collect the following types of personal information when you visit our
                                        website:
                                        <ul>
                                            <li>Contact Information: When you fill out the "Contact Us" form on our
                                                website, we may collect your name,
                                                email address, phone number, and any other information you provide.
                                            </li>
                                            <li>Usage Information: We may collect information about how you use our
                                                website, including your IP address,
                                                browser type, and browsing behavior.
                                            </li>
                                            <li>Cookies: We may use cookies to enhance your browsing experience and
                                                collect information about your
                                                interactions with our website. You can control cookie preferences
                                                through your browser settings.
                                            </li>
                                        </ul>
                                    </MKTypography>
                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        How We Use Your Information
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text">
                                        We may use your personal information for the following purposes:
                                        <ul>
                                            <li>To respond to your inquiries and provide you with the information or
                                                services you request.
                                            </li>
                                            <li>To improve our website and user experience.</li>
                                            <li>To analyze website traffic and usage patterns.</li>
                                            <li>To comply with legal obligations and protect our rights.</li>
                                        </ul>
                                    </MKTypography>
                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        Disclosure of Your Information
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text">
                                        We may disclose your personal information to third parties in the following
                                        circumstances:
                                        <ul>
                                            <li>To our service providers who assist us in operating our website and
                                                providing services to you.
                                            </li>
                                            <li>If required by law or to protect our legal rights.</li>
                                        </ul>
                                        We do not sell, rent, or trade your personal information to third parties for
                                        marketing purposes.
                                    </MKTypography>

                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        Your Choices
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text">
                                        You have the following choices regarding your personal information:
                                        <ul>
                                            <li>You can update or request deletion of your personal information by
                                                contacting us.
                                            </li>
                                            <li>You can adjust your browser settings to control cookies.</li>
                                        </ul>
                                    </MKTypography>

                                    <MKTypography variant="h5" mt={6} mb={3}>
                                        Contact Us
                                    </MKTypography>
                                    <MKTypography variant="body2" color="text">
                                        If you have any questions or concerns about this Privacy Policy or our data
                                        practices, please contact us via our contact us page.
                                    </MKTypography>
                                </MKBox>
                            </Card>
                        </Grid>
                    </Grid>
                </Container>
            </MKBox>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes}/>
            </MKBox>
        </>
    );
}

export default Privacy;
