// @mui icons
// Material Kit 2 PRO React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logo-ct-dark.png";

const date = new Date().getFullYear();

export default {
    brand: {
        name: "Aquire360",
        image: logoCT,
        route: "/",
    },
    socials: [
        // {
        //   icon: <FacebookIcon />,
        //   link: "https://www.facebook.com/CreativeTim/",
        // },
        // {
        //   icon: <TwitterIcon />,
        //   link: "https://twitter.com/creativetim",
        // },
        // {
        //   icon: <GitHubIcon />,
        //   link: "https://github.com/creativetimofficial",
        // },
        // {
        //   icon: <YouTubeIcon />,
        //   link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
        // },
    ],
    menus: [
        {
            name: "company",
            items: [
                {name: "about us", route: "/pages/company/about-us"},
                // { name: "freebies", href: "https://www.aquire360.com/templates/free" },
                // { name: "premium tools", href: "https://www.aquire360.com/templates/premium" },
                // { name: "blog", href: "https://www.aquire360.com/blog" },
            ],
        },
        // {
        //   name: "resources",
        //   items: [
        //     { name: "illustrations", href: "https://iradesign.io/" },
        //     { name: "bits & snippets", href: "https://www.aquire360.com/bits" },
        //     { name: "affiliate program", href: "https://www.aquire360.com/affiliates/new" },
        //   ],
        // },
        {
            name: "help & support",
            items: [
                {name: "contact us", route: "/pages/support/contact-us"},
                // { name: "knowledge center", href: "https://www.aquire360.com/knowledge-center" },
                // { name: "custom development", href: "https://services.creative-tim.com/" },
                // { name: "sponsorships", href: "https://www.aquire360.com/sponsorships" },
            ],
        },
        {
            name: "legal",
            items: [
                // { name: "terms & conditions", href: "https://www.aquire360.com/terms" },
                {name: "privacy policy", route: "/pages/support/privacy"},
                // { name: "licenses (EULA)", href: "https://www.aquire360.com/license" },
            ],
        },
    ],
    copyright: (
        <MKTypography variant="button" fontWeight="regular">
            All rights reserved. Copyright &copy; {date} Aquire360 by{" "}
            <MKTypography
                component="a"
                href="https://trevor.aeitek.org"
                target="_blank"
                rel="noreferrer"
                variant="button"
                fontWeight="regular"
            >
                AEI Tek
            </MKTypography>
            .
        </MKTypography>
    ),
};
